import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(196, 196, 196);
  background: radial-gradient(
    circle,
    rgba(196, 196, 196, 1) 0%,
    rgba(126, 126, 126, 1) 50%,
    rgba(56, 56, 56, 1) 100%
  );
`;

export const Window = styled.div`
  padding: 15px;
  max-width: 90vw;
  max-height: 80vh;
  background-color: white;
  border-radius: 10px;
  opacity: 0.9;
  overflow: hidden;
`;

export const Title = styled.h2`
  margin: 15px 25px;
  color: #444;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  & label {
    color: #555;
  }

  & input {
    border-radius: 7px;
    color: #555;
    border: 2px solid #555;
    margin: 3px 0 10px;
    padding: 10px;
    width: 350px;
    max-width: 85vh;
    font-size: 105%;
  }

  & input:active {
    background-color: white;
  }

  & input[type="submit"] {
    margin-top: 20px;
    background-color: #555;
    width: 100%;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    border: none;
  }

  & input[type="submit"]:hover {
    opacity: 0.8;
  }
`;

export const LinkButton = styled(Link)`
  margin-top: 10px;
  background-color: #555;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 110%;
  cursor: pointer;
  border-radius: 7px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    opacity: 0.8;
  }
`;

export const ErrorText = styled.p`
  color: red;
`;
