import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container, LinkButton, Title, Window } from "../styles";

export default function HomePage() {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const checkToken = () => {
      const token = localStorage.getItem("token");

      if (token) {
        const username = localStorage.getItem("username");

        setUsername(username);
        setToken(token);

        setLoading(false);
      } else navigate("/login");
    };

    window.addEventListener("storage", checkToken);
    setTimeout(checkToken, 1000);
  }, [navigate]);

  const handleOpenSession = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/request-kasm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const resData = await res.json();

      const { success, error, message, data } = resData;

      if (success) {
        window.location.href = data.kasm_url;
      }

      if (error) {
        if (message === "Access denied") {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          alert("Please login again");
          navigate("/login");
        } else {
          alert(message);
        }
      }
    } catch (e) {
      alert("Unable to communicate with server");
    }
  };

  const handleLogout = () => {
    setLoading(true);
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    window.location.reload(true);
  };

  return (
    <Container>
      <Window>
        {loading && <Title>Loading...</Title>}
        {!loading && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Title>Welcome, {username}</Title>

            <LinkButton to="#" onClick={handleOpenSession}>
              Open Session
            </LinkButton>

            <LinkButton to="#" onClick={handleLogout}>
              Log out
            </LinkButton>
          </div>
        )}
      </Window>
    </Container>
  );
}
