import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  ErrorText,
  Form,
  LinkButton,
  Title,
  Window,
} from "../styles";

export default function RegisterPage(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const checkToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        navigate("/");
        return;
      }

      setLoading(false);
    };

    window.addEventListener("storage", checkToken);
    setTimeout(checkToken, 1000);
  }, [navigate]);

  const loginHandler = async (e) => {
    setLoading(true);

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    const data = await res.json();
    const { success, error, message } = data;

    if (success) {
      const { username, token } = data.data;
      localStorage.setItem("token", token);
      localStorage.setItem("username", username);
      navigate("/");
    }

    if (error) setError(message);

    setLoading(false);

    e.preventDefault();
  };

  const onChangeValue = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container>
      <Window>
        <Title>{loading ? "Loading..." : "Register"}</Title>

        {!loading && (
          <>
            <Form onSubmit={loginHandler}>
              <label htmlFor="username">Username:</label>
              <input
                id="username"
                name="username"
                value={credentials.username}
                placeholder="Enter Username"
                onChange={onChangeValue}
              />

              <label htmlFor="password">Password:</label>
              <input
                id="password"
                type="password"
                name="password"
                value={credentials.password}
                placeholder="Enter Password"
                onChange={onChangeValue}
              />
              {error && <ErrorText>* {error}</ErrorText>}

              <input type="submit" value="Register" />

              <hr />

              <LinkButton to="/login">Login</LinkButton>
            </Form>
          </>
        )}
      </Window>
    </Container>
  );
}
